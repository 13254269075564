import { useContext } from "react"
import { HistoryContext } from "../../Contexts/HistoryContext"
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useNavigate } from "react-router-dom";

const Bredcrumbs = () => {
    const {history, historyPush, clearHistory} = useContext(HistoryContext) as any
    const navigate = useNavigate()
  const mapping : any= {
    frontendApplications : "Frontend Applications",
    backendApplications : "Backend Applications",
    kubernetesNativeApps:"Kubernetes Native Apps",
    tenants :"Tenants",
    repositories:"Repositories",
    domains :"Domains"
  }
  return (
<Breadcrumb style={{background: 'white'}}>
   {history?.length>1 &&  history.map((loc : any, index: any) => {
        const last = index === history.length - 1;
        return last ?
        <Breadcrumb.Item active onClick={()=> navigate(loc.path)}>{mapping[loc.name]? mapping[loc.name] : loc.name}</Breadcrumb.Item>
        : <Breadcrumb.Item onClick={()=> navigate(loc.path)}>{mapping[loc.name]? mapping[loc.name] : loc.name}</Breadcrumb.Item>  
        })}

</Breadcrumb>
  )
}

export default Bredcrumbs