import React, { useState, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import useFetchToken from './Auth0TokenUtil';
import { HistoryContext } from '../Contexts/HistoryContext';
import InfoToolTip from './Extras/InfoToolTip';
import { faL } from '@fortawesome/free-solid-svg-icons';
import {  payloadObjectVerification } from './Helpers/payloadObjectVerification';
import { FaSpinner } from 'react-icons/fa';

interface RepositoryGroup {
  id: number;
  name: string;
}

interface RepositoryType {
  repositoryType: string;
  id: number;
}

interface Tenant {
  id: number ;
  tenantName: string;
  clientId: string;
  region: string;
  environmentType: 'Shared' | 'Dedicated';
  codeRepositoryGroupId: number | undefined;
  codeRepositoryGroupName?: string;
  codeRepositoryId: number | undefined;
  domainId: number | undefined;
  tenantStatus: string;
}

interface Domain {
  id: number;
  domainName: string;
}

const Tenants: React.FC = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation()
  const fetchToken = useFetchToken();
  const {historyPush} = useContext(HistoryContext) as any

  const [newTenant, setNewTenant] = useState<Tenant>({
    id: 0,
    tenantName: '',
    codeRepositoryId: undefined,
    codeRepositoryGroupId: undefined,
    region: 'US',
    environmentType: 'Shared',
    domainId: undefined,
    clientId: user?.sub || '',
    tenantStatus: 'PREPARING'
  });
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [repositoryGroups, setRepositoryGroups] = useState<RepositoryGroup[]>([]);
  const [repositoryTypes, setRepositoryTypes] = useState<RepositoryType[]>([]);
  const [domains, setDomains] = useState<Domain[]>([]);
  const [addTenentAPILoading, setAddTenentAPILoading]  = useState<boolean>(false);
  const [formError, setformError]  = useState<string>("");


  useEffect(() => {
    const fetchTenants = async () => {
      if (!user?.sub) return;

      const token = await fetchToken();

      try {
        const response = await axios.get(`${process.env.REACT_APP_TMS_BASE_URL}/tenants`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-client-id': user.sub
          }
        });
        setTenants(response.data);
      } catch (error) {
        console.error('Error fetching tenants:', error);
      }
    };

    fetchTenants();
  }, [user?.sub, fetchToken]);

  useEffect(() => {
    const fetchRepositoryTypes = async () => {
      const token = await fetchToken();
      try {
        const response = await axios.get(`${process.env.REACT_APP_TMS_BASE_URL}/repositories`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-client-id': user?.sub
          }
        });
        setRepositoryTypes(response.data);
      } catch (error) {
        console.error('Error fetching repository types:', error);
      }
    };

    if (user?.sub) {
      fetchRepositoryTypes();
    }
  }, [user?.sub, fetchToken]);

  const fetchRepositoryGroupData = async (codeRepositoryId : any) => {
    if (!codeRepositoryId) return; // Don't fetch groups if no repository type is selected
    const token = await fetchToken();
    try {
      const repositoryGroupResponse = await axios.get(`${process.env.REACT_APP_TMS_BASE_URL}/repositories/top-level-groups?repository_type=${codeRepositoryId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-client-id': user?.sub
        }
      });
      const fetchedRepositoryGroups = repositoryGroupResponse.data;
      console.log("Fetched Repository Groups:", fetchedRepositoryGroups); // Debugging statement
      setRepositoryGroups(fetchedRepositoryGroups);
    } catch (error) {
      console.error('Error fetching repository groups:', error);
    }
  };

  useEffect(() => {
    // Fetch data only if a repository type is selected
    if (newTenant.codeRepositoryId !== 0) {
      fetchRepositoryGroupData(newTenant.codeRepositoryId);
    }
  }, [newTenant.codeRepositoryId, user?.sub, fetchToken]);

  useEffect(() => {
      const fetchDomains = async () => {
        const token = await fetchToken();
        try {
          const domainResponse = await axios.get(`${process.env.REACT_APP_TMS_BASE_URL}/domains`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'x-client-id': user?.sub
            }
          });
          setDomains(domainResponse.data);
        } catch (error) {
          console.error('Error fetching domains:', error);
        }
      };

      if (user?.sub) {
        fetchDomains();
      }
  }, [user?.sub, fetchToken]);

  const handleRepositoryTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if(event.target.value === 'configure-repository') {
      historyPush('/repositories', "Add New Repository")
      navigate('/repositories');
    } else {
      const selectedRepositoryTypeId = parseInt(event.target.value, 10);
      setNewTenant(prev => ({
        ...prev,
        codeRepositoryId: selectedRepositoryTypeId,
        codeRepositoryGroupId: 0 // Reset this since the repository group depends on the type
      }));
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    console.log("value", value);
    
    setNewTenant(prev => ({ ...prev, [name]: value }));
  };

  const handleEnvironmentTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value: 'Shared' | 'Dedicated' = event.target.value as 'Shared' | 'Dedicated';
    setNewTenant(prev => ({ ...prev, environmentType: value }));
  };

  const handleGroupChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedRepositoryGroupId = parseInt(e.target.value, 10);
    const selectedGroup = repositoryGroups.find(group => group.id === selectedRepositoryGroupId);
    if (selectedGroup) {
      setNewTenant(prevTenant => ({ ...prevTenant, codeRepositoryGroupId: selectedGroup.id }));
    }
  };

  const handleDomainChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if(e.target.value === 'configure-domain') {
      historyPush('/domains', "Add New Domain")
      navigate('/domains');
    } else {
      const domainId = parseInt(e.target.value, 10);
      const selectedDomain = domains.find(domain => domain.id === domainId);
      if (selectedDomain) {
        setNewTenant(prev => ({ ...prev, domainId: selectedDomain.id }));
      }
    }
  };


  const handleAddTenant = async () => {
    if (!user) {
      console.error("User data is unavailable.");
      return;
    }
  try {
      const topLevelRepoTypes = repositoryGroups.find(group => group.id === newTenant.codeRepositoryGroupId) as any
    const tenantToSave = {
      clientId: user.sub,
      tenantName: newTenant.tenantName,
      region: newTenant.region,
      environmentType: newTenant.environmentType,
      domainId: newTenant.domainId,
      codeRepositoryId: newTenant.codeRepositoryId,
      codeRepositoryGroupId: newTenant.codeRepositoryGroupId,
      codeRepositoryGroupName: topLevelRepoTypes?.full_name
    };
    const verifyPayload = payloadObjectVerification(tenantToSave)
    if(verifyPayload && verifyPayload[0] === true){
      console.log("Form Not Filled");
      setformError(` ${verifyPayload[1] == "tenantName" ? 'Please enter the ' : "Please select the"} ${verifyPayload[1]}`)
      return;
    }
    console.log("addTenentObjVerification",tenantToSave,  payloadObjectVerification(tenantToSave));
      setAddTenentAPILoading(true)
      const token = await fetchToken();
      const response = await axios.post(
        `${process.env.REACT_APP_TMS_BASE_URL}/tenants`,
        tenantToSave,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-client-id': user?.sub
          }
        }
      );

      if(response.status == 200 || response.status == 201){
        setAddTenentAPILoading(false)
        setTenants(prevTenants => [...prevTenants, response.data]);
        // Reset the newTenant form to clear inputs
        setaddNewTenent(false)
        setNewTenant({
          id: 0,
          tenantName: '',
          clientId: user.sub || '',
          codeRepositoryId: 0,
          codeRepositoryGroupId: 0,
          region: 'US',
          environmentType: 'Shared',
          domainId: 0,
          tenantStatus: 'PREPARING'
        });
    }
    } catch (error) {
      setAddTenentAPILoading(false)
      console.error('Error adding tenant:', error);
    }
  };

  const [addNewTenent, setaddNewTenent] = useState<boolean>(false)


useEffect(() => {
  const addNew = location.pathname === "/addNewTenants"
  setaddNewTenent(addNew)
}, [location])

  const deleteTenant = async (tenantId: number) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this tenant?");
    if (confirmDelete) {
      try {
        const token = await fetchToken();
        const response = await axios.delete(`${process.env.REACT_APP_TMS_BASE_URL}/tenants/${tenantId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-client-id': user?.sub
          }
        });
        if (response.status === 200) {
          setTenants(prevTenants => prevTenants.filter(tenant => tenant.id !== tenantId));
          console.log("Tenant deleted successfully");
        }
      } catch (error) {
        console.error('Error deleting tenant:', error);
      }
    }
  };

  const displayStatus = (tenantStatus: string) => {
    switch(tenantStatus) {
      case 'PREPARING':
        return (
          <span>
            {tenantStatus} <FaSpinner className="spinner" />
          </span>
        ); // Uses CSS animation for spinning
      default:
        return tenantStatus;
    }
  };

  const displayTenents = () => {
    return  <div className="body-list">
     <button onClick={()=> setaddNewTenent(true)} className="button" style={{float :'right'}}>Add Tenant</button>
    <h2>Tenants</h2>  
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Group</th>
          <th>Region</th>
          <th>Environment Type</th>
          <th>Domain</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {tenants.map((tenant : any)=> (
          <tr key={tenant.id}>
            <td>{tenant.tenantName || "-"}</td>
            <td>{tenant.codeRepositoryGroupName || ' - '}</td>
            <td>{tenant.region}</td>
            <td>{tenant.environmentType}</td>
            <td>{domains.find((d : any) => d.id === tenant.domainId)?.domainName || ''}</td>
            <td>{displayStatus(tenant.tenantStatus)}</td>
            <td>
                <button className="button" style={{ float: 'right' }} onClick={() => deleteTenant(tenant.id)}>
                  Delete
                </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  }
  return (
    <div className="body-column">
      <div className="body-section" style={{display: addNewTenent ? 'block' : 'none'}}>
        <h2>Add New Tenant</h2>
        <div>
          <label>Name:</label>
          <input type="text"
                 name="tenantName"
                 value={newTenant.tenantName}
                 onChange={handleInputChange}/>
        </div>
        <div>
          <label>Repository Type: {" "}<InfoToolTip tooltipText={"Git repository configuration is required for CoCREATE to auto generate application code.Select [Configure New Repository]option if you haven't configured a repository yet."} /></label>
          <select name="codeRepositoryId"
                  value={newTenant.codeRepositoryId || ''}
                  onChange={handleRepositoryTypeChange}>
            <option value="">Select Repository Type</option>
            {repositoryTypes.map((type) => (
              <option key={type.id} value={type.id}>{type.repositoryType}</option>
            ))}
            <option value="configure-repository">[Configure New Repository↗]</option>
          </select>
         </div>
        <div>
          <label>Repository Group: </label>
          <select name="codeRepositoryGroupId"
                  value={newTenant.codeRepositoryGroupId || ''}
                  onChange={handleGroupChange}
                 >
            <option value="">Select Repository Group</option>
            {repositoryGroups.map(group => (
              <option key={group.id} value={group.id}>{group.name}</option>
            ))}
          </select>
        </div>
        <div>
          <label>Region: {" "}<InfoToolTip tooltipText={"Region is a geographic location where your applications will be hosted, choose a region that is suitable for you."} /></label>
          <select name="region"
                  value={newTenant.region}
                  onChange={handleInputChange}>
            <option value="US">US</option>
            <option value="UK">UK</option>
            <option value="ASIA">ASIA</option>
          </select>
        </div>
        <div>
          <label>Environment Type: {" "}<InfoToolTip tooltipText={"DEDICATED - Enhanced security and isolation with a dedicated cluster, ideal for sensitive applications.<br/> SHARED- Cost-effective, offering isolation via namespaces in a shared environment for all tenants."} /></label>
          <select name="environmentType"
                  value={newTenant.environmentType}
                  onChange={handleEnvironmentTypeChange}>
            <option value="Shared">Shared</option>
            <option value="Dedicated">Dedicated</option>
          </select>
          </div>
        <div>
          <label>Domain: {" "}<InfoToolTip tooltipText={"Configure a domain like mycompany.com. Select <strong>[Configure New Domain]</strong> option if you haven't configured a domain yet."} /></label>
          <select name="domainId"
                  value={newTenant.domainId || ''}
                  onChange={handleDomainChange}>
            <option value="">Select Domain</option>
            {domains.map(domain => (
              <option key={domain.id} value={domain.id}>{domain.domainName}</option>
            ))}
            <option value="configure-domain">[Configure New Domain↗]</option>
          </select>
          </div>
          {formError? <span style={{color: 'red'}}>{formError}</span>: <></>}
          <div className='display-flex button-grp-save-cancel'>
            <button onClick={handleAddTenant} className="button">Add Tenant</button>
            <button onClick={() =>setaddNewTenent(false)} className="button btn btn-danger ml-2">Cancel</button>
          </div>

      </div>
     {!addNewTenent && displayTenents()}
    </div>
  );
};


export default Tenants;
