import { useLocation, useNavigate } from "react-router-dom";
import '../.././assets/css/Home.css';
// Example with React Icons
import { FaRocket, FaTools,  FaCode,
FaServer, FaHtml5, FaPlug, FaCodeBranch, FaCloudUploadAlt, FaUserSecret,
 FaEye, FaBook, FaNetworkWired, FaChartBar, FaCog, FaUsers, FaGlobe,
 FaBuilding, FaCreditCard, FaRobot, FaSyncAlt, FaBolt,FaCubes} from 'react-icons/fa';
import { useContext, useEffect, useState } from "react";
import { HistoryContext } from "../../Contexts/HistoryContext";
const SideBar: React.FC = () => {
    const {history, historyPush, clearHistory} = useContext(HistoryContext) as any
 
    const navigate = useNavigate();
      const location = useLocation();
      const [showDropDown, setShowDropDown] = useState("");
    
useEffect(() => {
    if(location.pathname === '/frontendApplications' || location.pathname === '/backendApplications'  || location.pathname === '/kubernetesNativeApps' ){
        setShowDropDown("applications")
    }
    if(location.pathname === '/sourceCodeDetails' || location.pathname === '/deployDetails'  || location.pathname === '/secretManagerDetails' ){
        setShowDropDown("devops")
    }
    if(location.pathname === '/logDetails' || location.pathname === '/traceDetails'  || location.pathname === '/metricsDetails' ){
        setShowDropDown("logs")
    }
    if(location.pathname === '/tenants' || location.pathname === '/repositories'  || location.pathname === '/domains' ){
        setShowDropDown("config")
    }
}, [location, navigate]);

      const navigateToRoute = (route : any) => {
        navigate(`/${route}`)
        clearHistory(route == "getStarted" ? null : `/${route}`)
      }
      console.log("history", history);
      
      return (
      
          <div className="nav-column">
            <nav>
              <button
                className={location.pathname === '/getStarted' ? 'active-menu-item' : ''}
                onClick={() => { navigateToRoute('getStarted'); }}
              >
                <FaBolt className="menu-icon" /> Get Started
              </button>
    
              <button onClick={() => setShowDropDown("applications")}>
                <FaCode className="menu-icon" /> Development
              </button>
              {showDropDown === "applications" && (
                <ul>
                  <li className={location.pathname === '/frontendApplications' ? 'active-menu-item' : ''} onClick={() => { navigateToRoute('frontendApplications'); }}><FaHtml5 className="menu-icon" /> Frontend</li>
                  <li className={location.pathname === '/backendApplications' ? 'active-menu-item' : ''} onClick={() => { navigateToRoute('backendApplications'); }}><FaServer className="menu-icon" /> Backend</li>
                  <li className={location.pathname === '/kubernetesNativeApps' ? 'active-menu-item' : ''} onClick={() => { navigateToRoute('kubernetesNativeApps'); }}><FaCubes className="menu-icon" /> Kubernetes Native</li>
                </ul>
              )}
              <button className={location.pathname === '/integrationHub' ? 'active-menu-item' : ''} onClick={() => { navigateToRoute('integrationHub'); }}>
                <FaPlug className="menu-icon" /> Integration Hub
              </button>
              <button onClick={() => setShowDropDown("devops")}>
                <FaTools className="menu-icon" /> DevOps
              </button>
              {showDropDown === "devops" && (
                  <ul>
                    <li className={location.pathname === '/sourceCodeDetails' ? 'active-menu-item' : ''} onClick={() => { navigateToRoute('sourceCodeDetails'); }}><FaRocket className="menu-icon" /> Code & Build</li>
                    <li className={location.pathname === '/deployDetails' ? 'active-menu-item' : ''} onClick={() => { navigateToRoute('deployDetails'); }}><FaSyncAlt className="menu-icon" /> Deployment</li>
                    <li className={location.pathname === '/secretManagerDetails' ? 'active-menu-item' : ''} onClick={() => { navigateToRoute('secretManagerDetails'); }}><FaUserSecret className="menu-icon" /> Secret Manager</li>
                  </ul>
                )}
              <button onClick={() => setShowDropDown("logs")}>
                <FaEye className="menu-icon" /> Observability
              </button>
              {showDropDown === "logs"  && (
                  <ul>
                    <li className={location.pathname === '/logDetails' ? 'active-menu-item' : ''} onClick={() => { navigateToRoute('logDetails'); }}><FaBook className="menu-icon" /> Logs</li>
                    <li className={location.pathname === '/traceDetails' ? 'active-menu-item' : ''} onClick={() => { navigateToRoute('traceDetails'); }}><FaNetworkWired className="menu-icon" /> Traces</li>
                    <li className={location.pathname === '/metricsDetails' ? 'active-menu-item' : ''} onClick={() => { navigateToRoute('metricsDetails'); }}><FaChartBar className="menu-icon" /> Metrics</li>
                  </ul>
              )}
              <button className={location.pathname === '/userManagement' ? 'active-menu-item' : ''} >
                <FaUsers className="menu-icon" /> User Management
              </button>
              <button className={location.pathname === '/billing' ? 'active-menu-item' : ''} >
                <FaCreditCard className="menu-icon" /> Billing
              </button>
              <button onClick={() => setShowDropDown("config")}>
                <FaCog className="menu-icon" /> Configuration
              </button>
              {showDropDown === "config" && (
                <ul>
                  <li className={location.pathname === '/tenants' ? 'active-menu-item' : ''} onClick={() => { navigateToRoute('tenants'); }}><FaBuilding className="menu-icon" /> Tenants</li>
                  <li className={location.pathname === '/repositories' ? 'active-menu-item' : ''} onClick={() => { navigateToRoute('repositories'); }}><FaCodeBranch className="menu-icon" /> Repositories</li>
                  <li className={location.pathname === '/domains' ? 'active-menu-item' : ''} onClick={() => { navigateToRoute('domains'); }}><FaGlobe className="menu-icon" /> Domains</li>
                </ul>
              )}
              <button onClick={() => { setShowDropDown("cloud") }}>
                  <FaRobot className="menu-icon" /> Infrastructure
              </button>
                {showDropDown === "cloud"  && (
                    <ul>
                      <li><FaCloudUploadAlt className="menu-icon" /> Amazon AWS</li>
                      <li><FaCloudUploadAlt className="menu-icon" /> Microsoft Azure</li>
                      <li><FaCloudUploadAlt className="menu-icon" /> Google Cloud</li>
                    </ul>
                )}
            </nav>
        </div>
      );
    };
    
    export default SideBar;
    