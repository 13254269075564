// src/components/DeployDetails.tsx
import React from 'react';

const DeployDetails: React.FC = () => {
  const argoCDUrl = 'https://argocd.colate.io';

  return (
    <div className="body-column">
      <div className="body-section">
        <h2>Automated Deployments with ArgoCD</h2>
        <p>Experience seamless and automated deployment processes with ArgoCD. We've set up an "App of Apps" configuration for you, meaning your applications are already configured in ArgoCD for deployment. Here’s how this benefits you:</p>
        <ol>
          <li>
            <strong>Seamless Automation:</strong> Our "App of Apps" pattern in ArgoCD automates the deployment of your applications. Once you push your code, ArgoCD takes care of the rest, deploying your applications according to the predefined configurations.
          </li>
          <li>
            <strong>Focus on What Matters:</strong> With the deployment processes automated, you can focus more on developing the business logic and features of your applications, rather than being bogged down by deployment complexities.
          </li>
          <li>
            <strong>Visibility and Control:</strong> Despite the automation, you maintain full visibility and control over your deployments. Log into ArgoCD to view your workloads, monitor deployments, and manage your applications as needed.
          </li>
          <li>
            Ready to view your automated deployments?
            <a href={argoCDUrl} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '5px', textDecoration: 'none' }}>
              Launch ArgoCD &#8594;
            </a>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default DeployDetails;
