import  {  createContext ,  useState } from 'react' ;

export const HistoryContext = createContext({}) ;
type historyPath = {
   path : string
   name : string
}
export const HistoryProvider : React.FC<{children: React.ReactNode}> = ({ children }) => {

     const [history, setHistory] = useState<historyPath[]>([]) ;  
     const historyPush = (path : string, name : any) => {
        const obj : historyPath = {
         path: path, 
         name: name || path?.split("/")[1]
        }
        setHistory([...history, obj])
     } 
     const clearHistory = (path : string) => {
      const obj : historyPath = {
         path: path, 
         name: path?.split("/")[1]
        }
        path ? setHistory([obj]) : setHistory([])
     } 
    return <HistoryContext.Provider value={{history,setHistory, historyPush, clearHistory}}>{children}</HistoryContext.Provider>
} 