import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import '../assets/css/Home.css';

interface Domain {
  txtCode: string;
  domainName: string;
  status: string;
}

const Domains: React.FC = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [domain, setDomain] = useState('colate.io');
  const [txtCode, setTxtCode] = useState('');
  const [verificationMessage, setVerificationMessage] = useState('');
  const [domains, setDomains] = useState<Domain[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [nameservers, setNameservers] = useState([]);

  useEffect(() => {
    // Fetch domains from the server
    const fetchDomains = async () => {
      try {
        if (user?.sub) {
          const accessToken = await getAccessTokenSilently();
          const response = await axios.get(`${process.env.REACT_APP_TMS_BASE_URL}/domains`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'x-client-id': user.sub
            }
          });
          setDomains(response.data);
        }
      } catch (error) {
        console.error('Error fetching domains:', error);
      }
    };

    fetchDomains();
  }, [user?.sub, getAccessTokenSilently]);

  const handleAddDomain = () => {
    if (domain && user?.sub) {
      var newTxtCode = '';
      if(domain.trim() !== 'colate.io') {
        newTxtCode = `TXT-${Math.random().toString(36).substring(2, 15)}`;
        setTxtCode(newTxtCode);
      }

      saveDomain(newTxtCode, domain);
      setDomain('colate.io');
    }
  };

  const saveDomain = async (txtCode: string, domainName: string) => {
    try {
      if (user?.sub) {
        const accessToken = await getAccessTokenSilently();
        const response = await axios.post(`${process.env.REACT_APP_TMS_BASE_URL}/domains`, {
          clientId: user.sub,
          domainName,
          txtCode
        }, {
            headers: { 'Authorization': `Bearer ${accessToken}` }
        });
        if (response.status === 200 && response.data) {
          // Add the returned domain object directly to the state
          setDomains(prevDomains => [...prevDomains, response.data]);
        }
      }
    } catch (error) {
      console.error('Error saving domain:', error);
    }
  };

  const verifyDomain = async (domainIndex: number) => {
    const domainToVerify = domains[domainIndex];

    if (user?.sub) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_TMS_BASE_URL}/domains/verify`, {
          domainName: domainToVerify.domainName,
          txtCode: domainToVerify.txtCode,
          clientId: user.sub
        });

        if (response.status === 200) {
          const updatedDomains = domains.map((d, idx) =>
            idx === domainIndex ? { ...d, status: 'Verified' } : d
          );
          setDomains(updatedDomains);

          const nameservers = response.data.nameservers;
          setNameservers(response.data.nameservers);
          const nameserverMessage = `Domain verification successful. Please update your domain's nameservers to the following`;

          setVerificationMessage(nameserverMessage);
          setIsSuccess(true);
        } else {
          setVerificationMessage('Domain verification failed.');
          setIsSuccess(false);
        }
      } catch (error) {
        setVerificationMessage('Error during domain verification. Please try again.');
        setIsSuccess(false);
        console.error('Error verifying domain:', error);
      }
    }
  };

  return (
    <div className="body-column">
      <div className="body-section">
        <h2>Domain Verification</h2>
        <p>
            Select the default domain (<strong>colate.io</strong>) or register your own. For custom domains, verify ownership by adding the provided TXT record to your DNS settings.
        </p>
        <div>
          <label>Enter domain:</label>
          <input
            type="text"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
          />
          <button className="button" style={{ marginLeft: '10px' }} onClick={handleAddDomain}>Add Domain</button>
        </div>
        {txtCode && (
          <div>
            <p>Your TXT Code:<strong> {txtCode}</strong></p>
            <p>Please copy this code and add it to your domain's DNS settings as a TXT record to verify ownership.</p>
            <p><strong>Important:</strong> This code is only displayed on this screen during domain creation!!</p>
          </div>
        )}
        <div className="body-list">
          <h2>Domains</h2>
          <table>
            <thead>
              <tr>
                <th>Domain Name</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {domains.map((domain, index) => (
                <tr key={index}>
                  <td>{domain.domainName}</td>
                  <td>{domain.status}</td>
                  <td>
                    {domain.status === 'Pending' && (
                      <button className="button" onClick={() => verifyDomain(index)}>Verify Now</button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {verificationMessage && (
          <div className={isSuccess ? 'success-alert' : 'failure-alert'}>
            <p>{verificationMessage}</p>
            {nameservers.length > 0 && (
              <ul>
                {nameservers.map((ns, idx) => (
                  <li key={idx}>{ns}</li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Domains;
