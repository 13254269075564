import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/css/Home.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaExternalLinkAlt} from 'react-icons/fa';
import { HistoryContext } from '../Contexts/HistoryContext';

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const gitLabUrl = 'https://gitlab.colate.io/colate/applications';
  const argoCDUrl = 'https://argocd.colate.io';
  const {historyPush} = useContext(HistoryContext) as any
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
const navigateTo = (path: string) => {
historyPush(path)
navigate(path)
}
  return (
    <div className="getting-started-grid">
      <div className="get-started-container">
        <h4>Get Started</h4>
        <div className="get-started-section">
          <h5>Start creating an <strong>application</strong> to harness the full potential of CoCREATE.</h5>
          <div className="action-buttons">
            <button onClick={() => navigateTo('/frontendApplications')} className="button" style={{ marginRight: '10px' }}>Frontend</button>
            <button onClick={() => navigateTo('/backendApplications')} className="button" style={{ marginRight: '10px' }}>Backend</button>
            <button onClick={() => navigateTo('/kubernetesNativeApps')} className="button">Kubernetes Native</button>
          </div>
        </div>
      </div>
      <div className="get-started-container">
          <h4>Next Steps</h4>
          <Slider {...settings} className="get-started-section">
            <div>
              <h5>Checkout the <strong>source code & CI/CD pipelines</strong> that are pre-configured for your applications to accelerate your development</h5>
              <div className="action-buttons">
                <button onClick={() => window.open(gitLabUrl, '_blank', 'noopener,noreferrer')} className="button">
                  Launch GitLab <FaExternalLinkAlt style={{ marginBottom: '5px', verticalAlign: 'middle' }} />
                </button>
              </div>
            </div>
            <div>
                <h5>Checkout the <strong>automated deployment</strong> process with <strong>ArgoCD</strong>, your applications are already configured for deployment</h5>
                <div className="action-buttons">
                  <button onClick={() => window.open(argoCDUrl, '_blank', 'noopener,noreferrer')} className="button">
                    Launch ArgoCD <FaExternalLinkAlt style={{ marginBottom: '5px', verticalAlign: 'middle' }} />
                  </button>
                </div>
            </div>
          </Slider>
      </div>
    </div>
  );
};

export default Dashboard;
