import { FaInfoCircle } from "react-icons/fa";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

type Info ={
    tooltipText : string
}
const InfoToolTip = (props: Info) => {

    const renderTooltip = (prop : any) => (
        <Tooltip id="button-tooltip" {...prop}>
        <div >  {props.tooltipText} </div>
        </Tooltip>
      );


  return (
    <OverlayTrigger
    placement="right"
    delay={{ show: 250, hide: 400 }}
    overlay={renderTooltip}
  >
   <span > <FaInfoCircle style={{marginTop: '-2px', color:'#0c78ca8f', marginLeft:'1px'}}/></span>
  </OverlayTrigger>
  )
}

export default InfoToolTip