import React from 'react';

interface RepositoryBoxProps {
  name: string;
  icon: string;
  description: string;
  configured: boolean;
  onConfigure: () => void;
}

const RepositoryBox: React.FC<RepositoryBoxProps> = ({ name, icon, description, configured, onConfigure }) => {
  return (
    <div className="repository-box">
      <img src={icon} alt={name} style={{ width: 60, height: 60 }} />
      <h5>{name}</h5>
      <p>{description}</p>
      <button disabled={configured} onClick={onConfigure} className="button">
        {configured ? 'Configured' : 'Configure'}
      </button>
    </div>
  );
};

export default RepositoryBox;
