// src/components/ServiceBox.tsx
import React from 'react';

interface ServiceBoxProps {
  name: string;
  icon: string;
  description: string;
}


const ServiceBox: React.FC<ServiceBoxProps> = ({ name, icon, description }) => {
  return (
    <div className="service-box">
      <img width="200px" height="100px" src={icon} alt={name} />
      <p>{description}</p> {/* Displaying the description */}
      <button className="button">Configure</button> {/* Configure button */}
    </div>
  );
};

export default ServiceBox;
