// src/components/SourceCodeDetails.tsx
import React from 'react';

const SourceCodeDetails: React.FC = () => {
  const gitLabUrl = 'https://gitlab.colate.io/colate/applications';

  return (
    <div className="body-column">
      <div className="body-section">
        <h2>Code & Build Simplified</h2>
        <p>With pre-configured CI/CD pipelines and custom pipeline templates, managing your source code and automating your build processes has never been easier:</p>
        <ol>
          <li>
            <strong>Pre-Configured Pipelines:</strong> Jumpstart your development with CI/CD pipelines that are pre-configured for your repositories. This setup ensures consistency across projects and accelerates your development cycle.
          </li>
          <li>
            <strong>Pipeline Templates:</strong> Customize your build and deployment processes with our pipeline templates designed to meet a wide range of development needs. These templates provide a solid foundation, reducing the need to write pipeline configurations from scratch.
          </li>
          <li>
            <strong>Benefits:</strong> These configurations not only streamline your development process but also:
            <ul>
              <li>Enhance code quality through automated testing.</li>
              <li>Facilitate faster release cycles, enabling continuous delivery.</li>
              <li>Improve collaboration and visibility across your development team.</li>
            </ul>
          </li>

          <a href={gitLabUrl} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '5px', textDecoration: 'none' }}>
            Launch GitLab &#8594;
          </a>
        </ol>
      </div>
    </div>
  );
};

export default SourceCodeDetails;
