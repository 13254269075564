import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { HistoryContext } from '../Contexts/HistoryContext';
import InfoToolTip from './Extras/InfoToolTip';

interface BackendApplication {
  clientId: string;
  appName: string;
  technology: 'Node.js' | 'Spring Boot';
  tenantName: string;
  appDomain: string;
  enableSSL: boolean;
  enableWAF: boolean;
}

interface SavedBackendApplication {
  id: number;
  clientId: string;
  appName: string;
  technology: string;
  appDomain: string;
  enableSSL: boolean;
  enableWAF: boolean;
  status: string;
  tenant: SavedTenant;
}

interface SavedTenant {
  tenantName: string;
  domain: SavedDomain;
}

interface SavedDomain {
  domainName: string;
}

interface Tenant {
  tenantName: string;
  domain: string;
}

const BackendApplications: React.FC = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();

  const [newApplication, setNewApplication] = useState<BackendApplication>({
    clientId: user?.sub || '', // Ensure clientId is initialized
    appName: '',
    technology: 'Node.js',
    tenantName: '',
    appDomain: '',
    enableSSL: false,
    enableWAF: false
  });

  const [applications, setApplications] = useState<SavedBackendApplication[]>([]);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<Tenant | undefined>(undefined);

  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const tenantResponse = await axios.get(`${process.env.REACT_APP_TMS_BASE_URL}/tenants`, {
          headers: { 'x-client-id': user?.sub },
        });

        const transformedTenants = tenantResponse.data.map((apiTenant: any) => ({
            tenantName: apiTenant.tenantName,
            domain: apiTenant.domain.domainName
        }));

        setTenants(transformedTenants);
      } catch (error) {
        console.error('Error fetching tenants:', error);
      }
    };

    const fetchApplications = async () => {
      try {
        const applicationResponse = await axios.get(`${process.env.REACT_APP_CO_CREATE_CONFIGURATIONS_BASE_URL}/applications/backend`, {
          headers: { 'x-client-id': user?.sub },
        });
        setApplications(applicationResponse.data);
      } catch (error) {
        console.error('Error fetching applications:', error);
      }
    };

    if (user?.sub) {
      fetchTenants();
      fetchApplications();
    }
  }, [user?.sub]);

  const handleAddApplication = async () => {
    try {
      // First API call: Create a new application
      const response = await axios.post(`${process.env.REACT_APP_CO_CREATE_CONFIGURATIONS_BASE_URL}/applications/backend`, newApplication, {
        headers: { 'x-client-id': user?.sub },
      });

      if (response.status === 200) {
        setApplications([...applications, response.data]);

        const appId = response.data.id;

        // Update the application status to 'CREATING'
        setApplications(prev => prev.map(app =>
          app.id === appId ? { ...app, status: 'CREATING' } : app
        ));

        // Second API call: Trigger some additional processing
        await axios.post(`${process.env.REACT_APP_CO_CREATE_CONFIGURATIONS_BASE_URL}/applications/backend/${appId}`);

        // Poll for status updates
        const pollInterval = setInterval(async () => {
          try {
            const statusResponse = await axios.get(`${process.env.REACT_APP_CO_CREATE_CONFIGURATIONS_BASE_URL}/applications/backend/${appId}`);
            const status = statusResponse.data.status;

            // Update application status in UI
            setApplications(prev => prev.map(app =>
              app.id === appId ? { ...app, status } : app
            ));

            // Stop polling if the application is deployed or if deployment failed
            if (status === 'DEPLOYED' || status === 'FAILED') {
              clearInterval(pollInterval);
            }
          } catch (error) {
            console.error('Error polling for status:', error);
          }
        }, 20000); // Poll every 20 seconds
      } else {
        throw new Error('Failed to create application');
      }
    } catch (error) {
      // Handle any errors in the process
      console.error('Error during application creation process:', error);
    }

    // Reset form fields
    setNewApplication({
      clientId: user?.sub || '',
      appName: '',
      technology: 'Node.js',
      tenantName: '',
      appDomain: '',
      enableSSL: false,
      enableWAF: false,
    });
    setSelectedTenant(undefined);
  };

  const {historyPush} = useContext(HistoryContext) as any

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const target = event.target as HTMLInputElement; // Type assertion here
      const { name, value, type } = target;
      const isCheckbox = type === 'checkbox';
      const checked = isCheckbox ? target.checked : undefined;

      setNewApplication(prev => ({
        ...prev,
        [name]: isCheckbox ? checked : value,
      }));

      if (name === 'tenantName') {
        if(value === 'add-new-tenant') {
        historyPush('/addNewTenants', "Add New Tenant")
        navigate('/addNewTenants');
        }

        const selected = tenants.find(tenant => tenant.tenantName === value);
        setSelectedTenant(selected);
      }
  };


  return (
    <div className="body-column">
      <div className="body-section">
        <h2>Add New Backend Application</h2>
        <div>
          <label>Name:</label>
          <input
            type="text"
            name="appName"
            value={newApplication.appName}
            onChange={handleInputChange}/>
        </div>
        <div>
          <label>Technology:</label>
          <select
            name="technology"
            value={newApplication.technology}
            onChange={handleInputChange}
          >
            <option value="Node.js">Node.js</option>
            <option value="Spring Boot">Spring Boot</option>
          </select>
        </div>
        <div>
          <label>Tenant:{" "}<InfoToolTip tooltipText={"A tenant is a logical environment where all your applications will be deployed, e.g. colate-dev, co-create-prod etc. Add one if you haven't added one already."} /></label>
          <select
            name="tenantName"
            value={newApplication.tenantName}
            onChange={handleInputChange}>
            <option value="">Select Tenant</option>
            {tenants.map(tenant => (
              <option key={tenant.tenantName} value={tenant.tenantName}>{tenant.tenantName}</option>
            ))}
            <option value="add-new-tenant">[Add New Tenant↗]</option>
          </select>
          </div>
        <button className="button" onClick={handleAddApplication}>Add Application</button>
      </div>
      <div className="body-list">
        <h2>Applications</h2>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Technology</th>
              <th>Tenant</th>
              <th>Domain</th>
              <th>SSL Enabled</th>
              <th>WAF Enabled</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {applications.map((app, index) => (
              <tr key={index}>
                <td>{app.appName}</td>
                <td>{app.technology}</td>
                <td>{app.tenant.tenantName}</td>
                <td>
                  {app.appDomain ? (
                    <>
                      <a
                        href={`http://${app.appDomain}.${app.tenant.domain.domainName}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {app.appDomain}.{app.tenant.domain.domainName}
                      </a>
                      <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: '5px' }} />
                    </>
                  ) : (
                    <>
                      <a
                          href={`http://${app.appDomain}.${app.tenant.domain.domainName}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {app.tenant.domain.domainName}
                        </a>
                        <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: '5px' }} />
                    </>
                  )}
                </td>
                <td>{app.enableSSL ? 'Yes' : 'No'}</td>
                <td>{app.enableWAF ? 'Yes' : 'No'}</td>
                <td>
                  {app.status === 'CREATING' ? (
                    <div className="spinner" />
                  )  : (
                    app.status
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BackendApplications;
