// src/components/SecretManagerDetails.tsx
import React from 'react';

const SecretManagerDetails: React.FC = () => {
  const vaultUrl = 'https://vault.colate.io'; // Update with your Vault instance URL

  return (
    <div className="body-column">
      <div className="body-section">
        <h2>Secret Management with Vault</h2>
        <p>Securely manage secrets and protect sensitive data through Vault. Vault secures, stores, and tightly controls access to tokens, passwords, certificates, API keys, and other secrets in modern computing:</p>
        <ol>
          <li>
            <strong>Centralized Secrets:</strong> Vault centralizes the management of all secrets across your infrastructure, ensuring consistent enforcement of access policies.
          </li>
          <li>
            <strong>Dynamic Secrets:</strong> Vault generates dynamic secrets on-demand for each part of your infrastructure, reducing the risk associated with static secrets.
          </li>
          <li>
            <strong>Data Encryption:</strong> Vault can encrypt and decrypt data without storing it, enabling secure data access and transfer across systems.
          </li>
          <li>
            <a href={vaultUrl} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '5px', textDecoration: 'none' }}>
              Launch Vault UI &#8594;
            </a>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default SecretManagerDetails;
