
import React from 'react';

const LogDetails: React.FC = () => {
  const grafanaUrl = 'https://grafana.colate.io/d/mOHkG9fSz/co-create-logs?orgId=1';

  return (
    <div className="body-column">
      <div className="body-section">
        <h2>Viewing Application Logs in Grafana</h2>
        <p>Follow these steps to view logs for a specific application in Grafana:</p>
        <ol>
          <li>
            <strong>Launch Grafana:</strong> Navigate to Grafana by
            <a href={grafanaUrl} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '5px' }}>
              <strong>clicking here</strong>
            </a>. Sign in by Auth0 if not already done.
          </li>
          <li>
            <strong>Enter Your Query:</strong> In the query editor of the Explore page, you can enter a query to filter logs for a specific application. To view logs for an application named "MyApp", enter the query:
            <code style={{ marginLeft: '5px' }}><strong>{'{{app="MyApp"}}'}</strong></code>. Replace "MyApp" with the actual name of your application.
          </li>
          <li>
            <strong>View Your Logs:</strong> After entering your query, click the "Run Query" button or press Shift+Enter to execute the query. Your application's logs will be displayed below the query editor.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default LogDetails;
