import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { HistoryContext } from '../Contexts/HistoryContext';
import InfoToolTip from './Extras/InfoToolTip';

interface KubernetesNativeApp {
  clientId: string;
  appName: string;
  tenantName: string;
  helmChartRepo: string;
  helmChartName: string;
  helmChartVersion: string;
  values: string;
}

interface SavedKubernetesNativeApp {
  id: number;
  clientId: string;
  appName: string;
  helmChartRepo: string;
  helmChartName: string;
  helmChartVersion: string;
  values: string;
  status: string;
  tenant: SavedTenant;
}

interface SavedTenant {
  tenantName: string;
  domain: SavedDomain;
}

interface SavedDomain {
  domainName: string;
}

interface Tenant {
  tenantName: string;
  domain: string;
}

const KubernetesNativeApps: React.FC = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();

  const [newApplication, setNewApplication] = useState<KubernetesNativeApp>({
    clientId: user?.sub || '', // Ensure clientId is initialized
    appName: '',
    tenantName: '',
    helmChartRepo: '',
    helmChartName: '',
    helmChartVersion: '',
    values: ''
  });

  const [applications, setApplications] = useState<SavedKubernetesNativeApp[]>([]);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<Tenant | undefined>(undefined);

  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const tenantResponse = await axios.get(`${process.env.REACT_APP_CO_CREATE_CONFIGURATIONS_BASE_URL}/tenants`, {
          headers: { 'x-client-id': user?.sub },
        });

        const transformedTenants = tenantResponse.data.map((apiTenant: any) => ({
            tenantName: apiTenant.tenantName
        }));

        setTenants(transformedTenants);
      } catch (error) {
        console.error('Error fetching tenants:', error);
      }
    };

    const fetchApplications = async () => {
      try {
        const applicationResponse = await axios.get(`${process.env.REACT_APP_CO_CREATE_CONFIGURATIONS_BASE_URL}/applications/kubernetes`, {
          headers: { 'x-client-id': user?.sub },
        });
        setApplications(applicationResponse.data);
      } catch (error) {
        console.error('Error fetching applications:', error);
      }
    };

    if (user?.sub) {
      fetchTenants();
      fetchApplications();
    }
  }, [user?.sub]);

  const handleAddApplication = async () => {
    try {
      // First API call: Create a new application
      const response = await axios.post(`${process.env.REACT_APP_CO_CREATE_CONFIGURATIONS_BASE_URL}/applications/kubernetes`, newApplication, {
        headers: { 'x-client-id': user?.sub },
      });

      if (response.status === 200) {
        setApplications([...applications, response.data]);

        const appId = response.data.id;

        // Update the application status to 'CREATING'
        setApplications(prev => prev.map(app =>
          app.id === appId ? { ...app, status: 'CREATING' } : app
        ));

        // Second API call: Trigger some additional processing
        await axios.post(`${process.env.REACT_APP_CO_CREATE_CONFIGURATIONS_BASE_URL}/applications/kubernetes/${appId}`);

        // Poll for status updates
        const pollInterval = setInterval(async () => {
          try {
            const statusResponse = await axios.get(`${process.env.REACT_APP_CO_CREATE_CONFIGURATIONS_BASE_URL}/applications/kubernetes/${appId}`);
            const status = statusResponse.data.status;

            // Update application status in UI
            setApplications(prev => prev.map(app =>
              app.id === appId ? { ...app, status } : app
            ));

            // Stop polling if the application is deployed or if deployment failed
            if (status === 'DEPLOYED' || status === 'FAILED') {
              clearInterval(pollInterval);
            }
          } catch (error) {
            console.error('Error polling for status:', error);
          }
        }, 20000); // Poll every 20 seconds
      } else {
        throw new Error('Failed to create application');
      }
    } catch (error) {
      // Handle any errors in the process
      console.error('Error during application creation process:', error);
    }

    // Reset form fields
    setNewApplication({
      clientId: user?.sub || '',
      appName: '',
          tenantName: '',
          helmChartRepo: '',
          helmChartName: '',
          helmChartVersion: '',
          values: ''
    });
    setSelectedTenant(undefined);
  };

  const {historyPush} = useContext(HistoryContext) as any
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const target = event.target as HTMLInputElement; // Type assertion here
      const { name, value, type } = target;

      if (name === 'tenantName') {
        if(value === 'add-new-tenant') {
        historyPush('/addNewTenants', "Add New Tenent")
        navigate('/addNewTenants');
        }

        const selected = tenants.find(tenant => tenant.tenantName === value);
        setSelectedTenant(selected);
      }
  };

  const [fieldFocus, setFieldFocus] = useState({
    tenantName: false,
    name: false,
    helmChartRepo: false,
    helmChartName: false,
    helmChartVersion: false
  });

  const handleFocus = (field: string) => {
    // setFieldFocus(prev => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field: string) => {
    // setFieldFocus(prev => ({ ...prev, [field]: false }));
  };

  return (
    <div className="body-column">
      <div className="body-section">
        <h2>Add New Kubernetes Native App</h2>
        <div>
          <label>Name:{" "}<InfoToolTip tooltipText={"Give a meaningful name to your application which will be used when hosting it, e.g. mywebsite.apps.colate.io"} /></label>
          <input
            type="text"
            name="appName"
            value={newApplication.appName}
            onChange={handleInputChange}
            onMouseOver={() => handleFocus('name')}
            onMouseOut={() => handleBlur('name')} />
          {/* {fieldFocus.name && <p className="field-description">Give a meaningful name to your application
          which will be used when hosting it, e.g. mywebsite.apps.colate.io</p>} */}
        </div>
        <div>
          <label>Tenant:{" "}<InfoToolTip tooltipText={"A tenant is a logical environment where all your applications will be deployed, e.g. colate-dev, co-create-prod etc. Add one if you haven't added one already."} /></label>
          <select
            name="tenantName"
            value={newApplication.tenantName}
            onChange={handleInputChange}>
            <option value="">Select Tenant</option>
            {tenants.map(tenant => (
              <option key={tenant.tenantName} value={tenant.tenantName}>{tenant.tenantName}</option>
            ))}
            <option value="add-new-tenant">[Add New Tenant↗]</option>
          </select>
          {/* {fieldFocus.tenantName && <p className="field-description">A tenant is a logical environment
          where all your applications will be deployed, e.g. colate-dev, co-create-prod etc. Add one if you haven't added one already.</p>} */}
        </div>
        <div>
          <label>Helm Chart Repo:{" "}<InfoToolTip tooltipText={"Provide the helm chart repo url"} /></label>
          <input
            type="text"
            name="helmChartRepo"
            value={newApplication.helmChartRepo}
            onChange={handleInputChange}
            onMouseOver={() => handleFocus('helmChartRepo')}
            onMouseOut={() => handleBlur('helmChartRepo')} />
          {/* {fieldFocus.helmChartRepo && <p className="field-description">Provide the helm chart repo url</p>} */}
        </div>
        <div>
          <label>Helm Chart Name:{" "}<InfoToolTip tooltipText={"Provide the helm chart name"} /></label>
          <input
            type="text"
            name="helmChartName"
            value={newApplication.helmChartName}
            onChange={handleInputChange}
            onMouseOver={() => handleFocus('helmChartName')}
            onMouseOut={() => handleBlur('helmChartName')} />
          {/* {fieldFocus.helmChartName && <p className="field-description">Provide the helm chart name</p>} */}
        </div>
        <div>
          <label>Helm Chart Version:{" "}<InfoToolTip tooltipText={"Provide the helm chart version"} /></label>
          <input
            type="text"
            name="helmChartVersion"
            value={newApplication.helmChartVersion}
            onChange={handleInputChange}
            onMouseOver={() => handleFocus('helmChartVersion')}
            onMouseOut={() => handleBlur('helmChartVersion')} />
          {/* {fieldFocus.helmChartVersion && <p className="field-description">Provide the helm chart version</p>} */}
        </div>
        <button className="button" onClick={handleAddApplication}>Add Application</button>
      </div>
      <div className="body-list">
        <h2>Applications</h2>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Tenant</th>
              <th>Helm Chart Name</th>
              <th>Helm Chart Version</th>
            </tr>
          </thead>
          <tbody>
            {applications.map((app, index) => (
              <tr key={index}>
                <td>{app.appName}</td>
                <td>{app.tenant.tenantName}</td>
                <td>{app.helmChartName}</td>
                <td>{app.helmChartVersion}</td>
                <td>
                  {app.status === 'CREATING' ? (
                    <div className="spinner" />
                  )  : (
                    app.status
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default KubernetesNativeApps;
