// src/components/IntegrationHub.tsx
import React from 'react';
import ServiceBox from './ServiceBox';
import mysqlIcon from '.././assets/images/mysql.png';
import mongoDBIcon from '.././assets/images/mongodb.png';
import kafkaIcon from '.././assets/images/kafka.png';
import esIcon from '.././assets/images/elasticsearch.png';
import redisIcon from '.././assets/images/redis.png';
import postgresIcon from '.././assets/images/postgres.png';
import rdsIcon from '.././assets/images/rds.png';

const services = [
  { name: 'MySQL', icon: mysqlIcon, description: 'A widely used open-source relational database management system.' },
  { name: 'MongoDB', icon: mongoDBIcon, description: 'A document-oriented NoSQL database used for high volume data storage.' },
  { name: 'Kafka', icon: kafkaIcon, description: 'A distributed streaming platform that enables you to build real-time streaming data pipelines and applications.' },
  { name: 'Elasticsearch', icon: esIcon, description: 'A search engine based on the Lucene library, providing a distributed, multitenant-capable full-text search engine with an HTTP web interface and schema-free JSON documents.' },
  { name: 'Redis', icon: redisIcon, description: 'An open-source, in-memory data structure store, used as a database, cache, and message broker.' },
  { name: 'Postgres', icon: postgresIcon, description: 'An advanced object-relational database management system that supports an extended subset of the SQL standard, including transactions, foreign keys, subqueries, triggers, user-defined types, and functions.' },
  { name: 'RDS', icon: rdsIcon, description: 'Amazon Relational Database Service (RDS) makes it easy to set up, operate, and scale a relational database in the cloud. It provides cost-efficient, resizable capacity for an industry-standard relational database and manages common database administration tasks.' }
];

const IntegrationHub: React.FC = () => {
  return (
    <div className="integration-hub">
      <h2>Integration Hub</h2>
      <div className="service-grid">
        {services.map(service => (
          <ServiceBox key={service.name} name={service.name} icon={service.icon} description={service.description} />
        ))}
      </div>
    </div>
  );
};

export default IntegrationHub;
