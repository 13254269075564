import { Routes, Route } from "react-router-dom";
import Login from './components/Login'
import 'bootstrap/dist/css/bootstrap.min.css';
import GetStarted from "./components/GetStarted";
import FrontendApplications from "./components/FrontendApplications";
import BackendApplications from "./components/BackendApplications";
import Tenants from "./components/Tenants";
import LogDetails from "./components/LogDetails";
import Domains from "./components/Domains";
import Repositories from "./components/Repositories";
import SourceCodeDetails from "./components/SourceCodeDetails";
import DeployDetails from "./components/DeployDetails";
import SecretManagerDetails from "./components/SecretManagerDetails";
import IntegrationHub from "./components/IntegrationHub";
import KubernetesNativeApps from "./components/KubernetesNativeApps";
import { useState } from "react";
import SideBar from "./components/Extras/Sidebar";
import Header from "./components/Extras/Header";
import { HistoryProvider } from "./Contexts/HistoryContext";
import Bredcrumbs from "./components/Extras/Bredcrumbs";
import MetricDetails from "./components/MetricDetails";
function App() {

  const [darkMode, setDarkMode] = useState(false);
  const toggleDarkMode = () => setDarkMode(!darkMode);

  return (
    <div className="App">
             <Routes>
      <Route path="/" element={<Login />} />
      </Routes>
      <HistoryProvider>
      <div className={darkMode ? 'dark-mode' : ''}>
        <Header darkMode={darkMode} toggleDarkMode={toggleDarkMode}/>
        <SideBar />
      <div className="content-column">
        <Bredcrumbs/>
      <Routes>
        {/* <Route path="/Home" element={<Home />} /> */}
        <Route path="/getStarted" element={<GetStarted />} />
        <Route path="/Home" element={<GetStarted />} />
         <Route path="/frontendApplications"element={<FrontendApplications />} />
          <Route path="/backendApplications"element={<BackendApplications />} />
          <Route path="/tenants"element={<Tenants />} />
          <Route path="/addNewTenants"element={<Tenants/>} />
          <Route path="/repositories"element={<Repositories />} />
          <Route path="/domains"element={<Domains />} />
          <Route path="/logDetails" element={<LogDetails />} />
          <Route path="/traceDetails" element={<LogDetails />} />
          <Route path="/metricsDetails" element={<MetricDetails />} />
          <Route path="/sourceCodeDetails"element={<SourceCodeDetails />} />
          <Route path="/deployDetails"element={<DeployDetails />} />
          <Route path="/secretManagerDetails"element={<SecretManagerDetails />} />
          <Route path="/integrationHub"element={<IntegrationHub />} />
          <Route path="/kubernetesNativeApps"element={<KubernetesNativeApps />} /> 
         </Routes> 
      </div>
    </div>
    </HistoryProvider>
    </div>
  );
}

export default App;
