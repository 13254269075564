import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import InfoToolTip from './Extras/InfoToolTip';
import { HistoryContext } from '../Contexts/HistoryContext';
import { payloadObjectVerification } from './Helpers/payloadObjectVerification';
import useFetchToken from './Auth0TokenUtil';
import { FaSpinner } from 'react-icons/fa';

interface FrontendApplication {
  clientId: string;
  appName: string;
  technology: 'REACT' | 'VUE' | 'ANGULAR';
  appType: 'FRONTEND';
  tenantName: string;
  appUrl: string;
}

interface SavedFrontendApplication {
  id: number;
  clientId: string;
  appName: string;
  appType: string;
  technology: string;
  appDomain: string;
  appUrl: string;
  appStatus: string;
  tenant: SavedTenant;
}

interface SavedTenant {
  tenantName: string;
  domain: SavedDomain;
}

interface SavedDomain {
  domainName: string;
}

interface Tenant {
  tenantName: string;
  domain: string;
}

const FrontendApplications: React.FC = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const [newApplication, setNewApplication] = useState<FrontendApplication>({
    clientId: user?.sub || '',
    appName: '',
    appType: 'FRONTEND',
    technology: 'REACT',
    tenantName: '',
    appUrl: '-',
  });
  const [formError, setformError] = useState<string>("");
  const [applications, setApplications] = useState<any>([]);
  const [tenants, setTenants] = useState<any>([]);
  const [selectedTenant, setSelectedTenant] = useState<Tenant | undefined>(undefined);
  const fetchToken = useFetchToken();

  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const token = await fetchToken();
        const tenantResponse = await axios.get(`${process.env.REACT_APP_TMS_BASE_URL}/tenants`, {
          headers: {
            'x-client-id': user?.sub,
            'Authorization': `Bearer ${token}`
          },
        });

        setTenants(tenantResponse.data);
      } catch (error) {
        console.error('Error fetching tenants:', error);
      }
    };

    const fetchApplications = async () => {
      try {
        const token = await fetchToken();
        const applicationResponse = await axios.get(`${process.env.REACT_APP_AMS_BASE_URL}/applications`, {
          headers: {
            'x-client-id': user?.sub,
            'Authorization': `Bearer ${token}`
          },
        });

        const appsWithUrls = applicationResponse.data.map((app: any) => ({
          ...app,
          appUrl: app.appAttributes?.appUrl || 'No URL Yet', // Map appUrl with fallback
        }));

        setApplications(appsWithUrls);
      } catch (error) {
        console.error('Error fetching applications:', error);
      }
    };

    if (user?.sub) {
      fetchTenants();
      fetchApplications();
    }
  }, [user?.sub]);

  const handleAddApplication = async () => {
    try {
      console.log("newApplication", newApplication);
      const payload = {
        appName: newApplication.appName,
        clientId: user?.sub,
        technology: newApplication.technology,
        appType: newApplication.appType,
        tenantId: newApplication.tenantName
      };
      const verifyPayload = payloadObjectVerification(payload);
      if (verifyPayload && verifyPayload[0] === true) {
        console.log("Form Not Filled");
        setformError(` ${verifyPayload[1] === "appName" ? 'Please enter the ' : "Please select the"} ${verifyPayload[1]}`);
        return;
      }
      const token = await fetchToken();
      const response = await axios.post(`${process.env.REACT_APP_AMS_BASE_URL}/applications`, payload, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-client-id': user?.sub
        }
      });

      if (response.status === 200) {
        const newApp = {
          ...response.data,
          appUrl: response.data.appAttributes?.appUrl || 'No URL Yet',
        };

        setApplications([...applications, newApp]);

        const appId = response.data.id;
        setApplications((prev: any) => prev.map((app: any) =>
          app.id === appId ? { ...app, appStatus: 'PREPARING' } : app
        ));
      } else {
        throw new Error('Failed to create application');
      }
    } catch (error) {
      console.error('Error during application creation process:', error);
    }

    setNewApplication({
      clientId: user?.sub || '',
      appName: '',
      technology: 'REACT',
      appType: 'FRONTEND',
      tenantName: '',
      appUrl: '-',
    });
    setSelectedTenant(undefined);
  };

  const { history, historyPush, clearHistory } = useContext(HistoryContext) as any;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const target = event.target as HTMLInputElement;
    const { name, value, type } = target;
    const isCheckbox = type === 'checkbox';
    const checked = isCheckbox ? target.checked : undefined;

    setNewApplication(prev => ({
      ...prev,
      [name]: isCheckbox ? checked : value,
    }));

    if (name === 'tenantName') {
      if (value === 'add-new-tenant') {
        historyPush('/addNewTenants', "Add New Tenant");
        navigate('/addNewTenants');
      }

      const selected = tenants.find((tenant: any) => tenant.tenantName === value);
      setSelectedTenant(selected);
    }
  };

  const deleteFrontendApp = async (appId: number) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this application?");
    if (confirmDelete) {
      try {
        const token = await fetchToken();
        const response = await axios.delete(`${process.env.REACT_APP_AMS_BASE_URL}/applications/${appId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-client-id': user?.sub
          }
        });
        if (response.status === 200) {
          setApplications((prevApplications: SavedFrontendApplication[]) =>
            prevApplications.filter((app: SavedFrontendApplication) => app.id !== appId)
          );
          console.log("Application deleted successfully");
        }
      } catch (error) {
        console.error('Error deleting application:', error);
      }
    }
  };

  const displayStatus = (appStatus: string) => {
    switch (appStatus) {
      case 'PREPARING':
        return (
          <span>
            {appStatus} <FaSpinner className="spinner" />
          </span>
        );
      default:
        return appStatus;
    }
  };

  return (
    <div className="body-column">
      <div className="body-section">
        <h2>Add New Frontend Application</h2>
        <div>
          <label>Name: {" "}<InfoToolTip tooltipText={"Give a meaningful name to your application which will be used when hosting it, e.g. mywebsite.apps.colate.io"} />
          </label>
          <input
            type="text"
            name="appName"
            value={newApplication.appName}
            onChange={handleInputChange} />
        </div>
        <div>
          <label>Technology:</label>
          <select
            name="technology"
            value={newApplication.technology}
            onChange={handleInputChange}
          >
            <option value="REACT">React</option>
            <option value="VUE">Vue.js</option>
            <option value="ANGULAR">AngularJs</option>
          </select>
        </div>
        <div>
          <label>Tenant: {" "} <InfoToolTip tooltipText={"A tenant is a logical environment where all your applications will be deployed, e.g. colate-dev, co-create-prod etc. Add one if you haven't added one already."} />
          </label>
          <select
            name="tenantName"
            value={newApplication.tenantName}
            onChange={handleInputChange}>
            <option value="">Select Tenant</option>
            {tenants.map((tenant: any) => (
              <option key={tenant.tenantName} value={tenant.id}>{tenant.tenantName}</option>
            ))}
            <option value="add-new-tenant">[Add New Tenant↗]</option>
          </select>
        </div>
        {formError ? <div style={{ color: 'red' }}>{formError}</div> : <></>}
        <button className="button" onClick={handleAddApplication}>Add Application</button>
      </div>
      <div className="body-list">
        <h2>Applications</h2>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Url</th>
              <th>Tenant</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {applications.map((app: any, index: number) => (
              <tr key={index}>
                <td>{app.appName}</td>
                <td>
                  {app.appUrl && app.appUrl !== '-' ? (
                    <a href={app.appUrl} target="_blank" rel="noopener noreferrer">
                      {app.appUrl} <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </a>
                  ) : 'No URL Yet'}
                </td>
                <td>{tenants.find((tenant: any) => tenant.id === app.tenantId)?.tenantName}</td>
                <td>{displayStatus(app.appStatus)}</td>
                <td>
                  <button className="button" style={{ float: 'right' }} onClick={() => deleteFrontendApp(app.id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FrontendApplications;
