import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useState } from "react";
import { FaBook, FaEnvelope, FaLifeRing, FaMoon, FaSearch, FaSignOutAlt, FaSun, FaUser } from "react-icons/fa";

const Header = ({toggleDarkMode, darkMode}: any) => {
const { user, isAuthenticated, logout } = useAuth0();
const [userDropdownOpen, setUserDropdownOpen] = useState(false);



  const toggleUserDropdown = () => {
    setUserDropdownOpen(!userDropdownOpen);
  };


  const contactUsUrl = 'https://colate.io/Contact';
  return (
    <div>
          <header className="header">
            <div className="colate-logo">
              <a href="">
                <h1><span className="co">co</span><span className="create">create</span></h1>
              </a>
            </div>
            <span className="documentation">
                <a>
                  <p><FaSearch /> Search</p>
                </a>
            </span>
            <span className="documentation">
                <a>
                  <p><FaLifeRing /> Support</p>
                </a>
            </span>
            <span className="documentation">
                <a>
                  <p><FaBook /> Documentation</p>
                </a>
            </span>
            <span className="documentation">
                <a href={contactUsUrl} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '5px', textDecoration: 'none' }}>
                  <FaEnvelope /> Discuss New Requirements
                </a>
            </span>
            <div className="dark-mode-toggle" onClick={toggleDarkMode}>
              {darkMode ? <FaSun /> : <FaMoon />}
              <span>{darkMode ? 'Light Mode' : 'Dark Mode'}</span>
            </div>
            <div className="user-details">
                {isAuthenticated && (
                  <div className="user-info" onClick={toggleUserDropdown}>
                    <span className="user-name">
                      <FaUser />
                    </span>
                    {userDropdownOpen && (
                      <div className="user-dropdown">
                        <div>{user?.name}</div>
                        <div>{user?.email}</div>
                        <button onClick={() => logout()} className="button">
                          <FaSignOutAlt /> Log Out
                        </button>
                      </div>
                    )}
                  </div>
                )}
            </div>
          </header>
      </div>
  )
}

export default Header