import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import RepositoryBox from './RepositoryBox';
import gitlabSaasIcon from '../assets/images/gitlab.png';
import gitlabSelfIcon from '../assets/images/gitlab.png';
import githubIcon from '../assets/images/github.png';
import bitbucketIcon from '../assets/images/bitbucket.png';

// Define repository types explicitly
type RepoType = 'GITLAB_SAAS' | 'GITLAB_SELF' | 'BITBUCKET' | 'GITHUB';

// Corrected RepositoryStatus to be an indexable type
interface RepositoryStatus {
  [key: string]: boolean;
}

const Repositories: React.FC = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [configuredRepos, setConfiguredRepos] = useState<RepositoryStatus>({});
  const [error, setError] = useState<string>('');

  const repoDetails = {
    GITLAB_SAAS: {
      name: 'Gitlab Cloud',
      icon: gitlabSaasIcon,
      description: 'More feature-rich than self-hosted options; may require a subscription.'
    },
    GITLAB_SELF: {
      name: 'CoCREATE Managed GitLab Enterprise',
      icon: gitlabSelfIcon,
      description: 'Complimentary for CoCREATE users, offering essential features.'
    },
    GITHUB: {
      name: 'GitHub Cloud',
      icon: githubIcon,
      description: 'Popular for open-source projects and integrates well with GitLab CI for seamless CI/CD pipelines.'
    },
    BITBUCKET: {
      name: 'Bitbucket Cloud',
      icon: bitbucketIcon,
      description: 'Ideal for professional teams, offering deep integration with Jira and Trello, and compatible with GitLab CI.'
    }
  };

  useEffect(() => {
    const fetchConfigurations = async () => {
      if (user?.sub) {
        try {
          const accessToken = await getAccessTokenSilently();
          const response = await axios.get(`${process.env.REACT_APP_TMS_BASE_URL}/repositories`, {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'x-client-id': user.sub
            }
          });
          const reposConfigured = response.data.reduce((acc: RepositoryStatus, repo: { repositoryType: RepoType }) => {
            acc[repo.repositoryType] = true;
            return acc;
          }, {});

          setConfiguredRepos(reposConfigured);
        } catch (error: any) {
          console.error('Error fetching repository configurations:', error);
          setError('Failed to fetch repository configurations');
        }
      }
    };

    fetchConfigurations();
  }, [user, getAccessTokenSilently]);

  const handleSignIn = (repoType: RepoType) => {
    if (user?.sub) {
      window.location.href = `${process.env.REACT_APP_TMS_BASE_URL}/oauth?client_id=${encodeURIComponent(user.sub)}&repository_type=${repoType}`;
    } else {
      setError('User authentication required.');
    }
  };

  return (
    <div className="body-column">
      <div className="body-section">
        <h2>Repositories</h2>
        <p>Authorize your Git repositories to allow CoCREATE to automatically set up your applications.</p>
        <div className="repository-grid">
          {Object.entries(repoDetails).map(([key, { name, icon, description }]) => (
            <RepositoryBox
              key={name}
              name={name}
              icon={icon}
              description={description}
              configured={!!configuredRepos[key]}
              onConfigure={() => handleSignIn(key as RepoType)}
            />
          ))}
        </div>
        {error && <div className="failure-alert">{error}</div>}
      </div>
    </div>
  );
};

export default Repositories;
